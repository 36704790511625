import { useRef } from 'react';
import { format } from 'date-fns';
import Countdown from 'react-countdown';
import Anime, { anime } from 'react-animejs-wrapper';
import { Link } from 'react-router-dom';

import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cards';

import { weddingDate } from 'components/utils';

/* Images */
import landing_img from '../assets/engagement/Daphne+AnthonyEngagement-37.jpg';
import dance_first from '../assets/engagement/Daphne+AnthonyEngagement-63.jpg';

import business_casual1 from '../assets/wedding_attire/bc1.jpg';
import business_casual2 from '../assets/wedding_attire/bc2.jpg';
import business_casual3 from '../assets/wedding_attire/bc3.jpg';
import business_casual4 from '../assets/wedding_attire/bc4.jpg';
import business_casual5 from '../assets/wedding_attire/bc5.jpg';
import business_casual6 from '../assets/wedding_attire/bc6.jpg';

import bells from '../assets/icons/wedding-bells.png';
import toast from '../assets/icons/toast.png';

import springhill from '../assets/springhill_room.jpg';
import wyndam from '../assets/wyndom_room.jpg';

function Home(){
    const welcomeRef = useRef<HTMLDivElement>(null);
    const countdownRef = useRef<HTMLDivElement>(null);
    const dressCodeRef = useRef<HTMLDivElement>(null);
    const eventRef = useRef<HTMLDivElement>(null);

    const pageScroll = (myRef, block) => {
        try {
            if(myRef && myRef.current){
                myRef.current.scrollIntoView({ behavior: 'smooth', block: block });
            }
        }
        catch (ex) {
            console.log(`Error moving to section ${ex}`);
        }
    }

    const renderer = ({ days, hours, minutes, completed }) => {
        if (completed) {
          // Render a completed state
          return <>
            <div className='description countdown-item'>Thank you</div>
            <div className='btn-container drop-links countdown-item'>
                <div className='drop-link' onClick={() => pageScroll(eventRef, 'end')}>
                    <span className="material-symbols-outlined">event</span>
                    <span>Wedding Events</span>
                </div>
            </div>
          </>;
        } else {
          // Render a countdown
          return <>
                <div className='countdown countdown-item'>{days} days, {hours} hours, and {minutes} minutes</div>
                <div className='description countdown-item'>Until Our Wedding Celebration</div>
                
                {/*<div className='btn-container countdown-item'>
                    <Link to="/rsvp" className='solid-btn c2'>RSVP</Link>
                </div>*/}
            </>;
        }
    };

    return (
        <div className="page-container home">
            <section className='landing-section'>
                <div className='img-container'>
                    <img src={landing_img} alt="Anthony & Daphne Welcome Image"/>
                </div>

                <div className='landing-content'>
                    <h1>Anthony</h1>
                    <h1>&</h1>
                    <h1>Daphne</h1>
                    {/* <div className='signature'>Are Getting Married</div> */}
                    {/* <h2>{format(weddingDate, 'MM.dd.yy')}</h2> */}
                    <div className='signature'>
                        <Anime 
                            config={{
                                opacity: [0,1], delay:anime.stagger(150, { start: 150 }),
                                duration: 2250, easing: "easeInOutQuad"
                            }}>
                            {Array.from("Are Getting Married").map((lt,i)=> <span className="letter" key={i}>{lt}</span>)}
                        </Anime>
                    </div>
                    <Anime 
                        config={{
                            opacity: [0,1], duration: 1500, easing: "easeInOutQuad",
                            delay:function(evt,i) { return (Array.from("Are Getting Married").length * 150)},
                        }}>
                        <h2>{format(weddingDate, 'MM.dd.yy')}</h2>
                    </Anime>
                </div>
                
                {/* More Link */}
                <div className='more-drop-link' onClick={() => pageScroll(welcomeRef, 'end')}>
                    <span>More Information</span>
                    <span className="material-symbols-outlined">keyboard_double_arrow_down</span>
                </div>                
            </section>

            <section className='welcome-address' ref={welcomeRef}>
                <p>
                    Friends & Family we are beyond excited and grateful to be able to share this important day with you all.
                    We invite you to leave behind the worries and concerns of every day life and join us in this celebration.
                </p>
                <p>Our website has all of the information for our guest for that day including the RSVP form for invited guests.</p>
                
                <div className='btn-container drop-links'>
                    <div className='drop-link' onClick={() => pageScroll(countdownRef, 'end')}>
                        <span className="material-symbols-outlined">event</span>
                        <span>Save The Date</span>
                    </div>

                    <div className='drop-link' onClick={() => pageScroll(eventRef, 'start')}>
                        <span className="material-symbols-outlined">cake</span>
                        <span>Wedding Events</span>
                    </div>

                    <div className='drop-link' onClick={() => pageScroll(eventRef, 'end')}>
                        <span className="material-symbols-outlined">featured_seasonal_and_gifts</span>
                        <span>Our Registry</span>
                    </div>

                    <Link className='drop-link' to="/faqs">
                        <span className="material-symbols-outlined">help</span>
                        <span>FAQs</span>
                    </Link>
                </div>

                <div className='signature'>Thank you</div>
            </section>

            <section className='wedding-countdown' ref={countdownRef}>
                <div className='countdown-container'>
                    <div className='save-the-date countdown-item'>
                        <div className='title'>Save The Date</div>
                        <div className='date'>{format(weddingDate, 'EEEE, MMMM dd, yyyy')}</div>
                    </div>
                    <Countdown date={weddingDate} intervalDelay={30000} renderer={renderer} />
                    <div className='custom-back' />
                </div>
            </section>

            <section className="wedding-dressCode" ref={dressCodeRef}>
                <div className='dressCode-container'>
                    <div className='code-text'>
                        <h1>Dress Code</h1>
                        <p>Our wedding day being set in September to help with what to wear we are setting our wedding day dress code as <span>'wedding casual'</span>.</p>
                        <p><span>'When it says casual, men could show up in pants and a blazer with no tie—or maybe not a jacket at all—but just a nice shirt and a pair of slacks. Women can wear a nice sundress or dress, a jumpsuit or even a two-piece set,'</span> says Julie Sabatino, bridal stylist and founder of The Stylish Bride.</p>
                    </div>

                    <div className='card-container'>
                        <Swiper effect={'cards'} grabCursor={true} modules={[EffectCards]} className="dress-swiper">
                            <SwiperSlide>
                                <img src={business_casual1} alt='Dress code image' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={business_casual2} alt='Dress code image' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={business_casual3} alt='Dress code image' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={business_casual4} alt='Dress code image' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={business_casual5} alt='Dress code image' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={business_casual6} alt='Dress code image' />
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </section>

            <section className='wedding-events' ref={eventRef}>
                <div className="anchor" id="weddingEvents" />
                <img src={dance_first} alt="Couple image" className='back-img' />

                <div className='event-container'>
                    <h1>Our Wedding Events</h1>

                    <div className='event-list-container'>
                        {/* Wedding Ceremony */}
                        <div className='event'>
                            <div className='content-section'>
                                <div className='content'>
                                    <img src={bells} className='content-icon'/>
                                    <h2>Wedding Ceremony</h2>
                                    <div className='info-line'>
                                        <span className='line-title'>Date</span>
                                        <span className='line-text'>{format(weddingDate, 'do MMMM, yyyy')}</span>
                                    </div>

                                    <div className='info-line'>
                                        <span className='line-title'>Time</span>
                                        <span className='line-text'>{format(weddingDate, 'hh:mm aaaa')}</span>
                                    </div>

                                    <div className='info-line'>
                                        <span className='line-title'>Location</span>
                                        <span className='line-text'>King Cole Farm</span>
                                        <span className='line-text'>1730 Bayside Dr.</span>
                                        <span className='line-text'>Dover, DE. 19901</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Wedding Reception */}
                        <div className='event'>
                            <div className='content-section g2'>
                                <div className='content'>
                                    <img src={toast} className='content-icon'/>
                                    <h2>Reception</h2>
                                    <div className='info-line'>
                                        <span className='line-title'>Date</span>
                                        <span className='line-text'>{format(weddingDate, 'do MMMM, yyyy')}</span>
                                    </div>

                                    <div className='info-line'>
                                        <span className='line-title'>Time</span>
                                        <span className='line-text'>Immediately Following Ceremony</span>
                                    </div>

                                    <div className='info-line'>
                                        <span className='line-title'>Location</span>
                                        <span className='line-text'>King Cole Farm - Pole Barn</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='accommedations-container'>
                        <h2>Guest Accommedations</h2>
                        <p>We're thrilled to announce our wedding date and we'd be overjoyed to have you join us in celebration. 
                            To ensure that our guests can enjoy the evening in full with us we've secured a block of rooms at the <span>Marriot SpringHill Suites</span> as well as the <span>Wyndham Microtel</span>, which are both in close proximity to our venue.
                            We don't want you to miss out on securing a room, so we kindly suggest you make your reservations early to ensure a comfortable stay.
                        </p>

                        <p className='special-text'><span>Deadline for our rate: August 30th, 2024</span></p>

                        <div className='room-list-container'>
                            <a className='room-container' target="_blank" href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1710169238722&key=GRP&app=resvlink">
                                <div className='img-container'>
                                    <img src={springhill} alt="Springhill suites image" />
                                </div>
                                <div className='room-content'>
                                    <div className='content-section content-title'>SpringHill Suites: <span>Frederica</span></div>
                                    <div className='content-section content-info'>
                                        <span className="material-symbols-outlined">location_on</span>
                                        208 Dominus Dr, Frederica, DE 19946
                                    </div>
                                    <div className='content-section content-date'>
                                        <span className="material-symbols-outlined">event</span>
                                        Fri, Sep 20 - Sun, Sept 22
                                    </div>
                                </div>
                            </a>

                            <a className='room-container' target="_blank" href="https://www.wyndhamhotels.com/microtel/dover-delaware/microtel-inn-and-suites-dover-de/rooms-rates?&checkInDate=09/21/2024&checkOutDate=09/22/2024&groupCode=92124">
                                <div className='img-container'>
                                    <img src={wyndam} alt="Wyndam suites image" />
                                </div>
                                <div className='room-content'>
                                    <div className='content-section content-title'>Microtel Inn & Suites by Wyndham <span>Dover</span></div>
                                    <div className='content-section content-info'>
                                        <span className="material-symbols-outlined">location_on</span>
                                        1703 E Lebanon Rd, Dover, DE 19901
                                    </div>
                                    <div className='content-section content-date'>
                                        <span className="material-symbols-outlined">event</span>
                                        Sat, Sep 21 - Sun, Sept 22
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Home;