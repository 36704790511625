import StoryBook from 'components/storyBook';
import header_img from '../assets/engagement/Daphne+AnthonyEngagement-32.jpg';

function OurStory(){

    return (
        <div className="page-container our-story">
            <section className='inner-page-title'>
                <div className='page-img-cover'>
                    <img src={header_img} alt="Couple image" className='page-img' />
                    <img src={header_img} alt="Couple image" className='page-img cover' />
                </div>
                
                <h1>Our Story</h1>
            </section>

            <section className='book-container'>
                <StoryBook />
            </section>
        </div>
    );
}

export default OurStory;