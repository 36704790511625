import header_img from '../assets/engagement/Daphne+AnthonyEngagement-16.jpg';

import amazon_img from '../assets/amazon-logo.png';
//import target_img from '../assets/target-logo.png';

function Registry(){

    return (
        <div className="page-container registry">
            <section className='inner-page-title'>
                <div className='page-img-cover'>
                    <img src={header_img} alt="Couple image" className='page-img' />
                    <img src={header_img} alt="Couple image" className='page-img cover' />
                </div>
                
                <h1>Registry</h1>
            </section>

            <section className='registry-title-container'>
                <p>
                    We're looking forward to celebrating with you on our big day. 
                    Having you attend our wedding is the greatest gift of all, however, 
                    if you're looking for wedding gift ideas, 
                    we've registered here for things that we need to outfit our home
                </p>
            </section>

            <section className='registry-list-container'>
                <a className='registry-item' target="_blank" href="https://www.amazon.com/wedding/share/pearsonmyheart24">
                    <div className='img-container'>
                        <img src={amazon_img} alt='amazon logo' />
                    </div>
                    <div className='item-content'>
                        <span>Our Amazon Registry</span>
                    </div>
                </a>
            </section>
        </div>
    );
}

export default Registry;