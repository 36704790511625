import { useEffect, useState, useRef } from 'react';
import ReactGA from "react-ga4";

import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';

import 'rodal/lib/rodal.css';
import Rodal from 'rodal';

import { GalleryCol, GalleryColItem, GalleryColRow, GalleryNavItem } from 'datatypes/types';

import header_img from '../assets/engagement/Daphne+AnthonyEngagement-84.jpg';
import cherry_b from 'assets/cherry_b_bw.png';

/* Gallery Images */
/* Engagement */
import eg1 from '../assets/engagement/Daphne+AnthonyEngagement-1.jpg'; import eg2 from '../assets/engagement/Daphne+AnthonyEngagement-4.jpg';
import eg3 from '../assets/engagement/Daphne+AnthonyEngagement-8.jpg'; import eg4 from '../assets/engagement/Daphne+AnthonyEngagement-16.jpg';
import eg5 from '../assets/engagement/Daphne+AnthonyEngagement-19.jpg'; import eg6 from '../assets/engagement/Daphne+AnthonyEngagement-20.jpg';
import eg7 from '../assets/engagement/Daphne+AnthonyEngagement-22.jpg'; import eg8 from '../assets/engagement/Daphne+AnthonyEngagement-26.jpg';
import eg9 from '../assets/engagement/Daphne+AnthonyEngagement-32.jpg'; import eg10 from '../assets/engagement/Daphne+AnthonyEngagement-37.jpg';
import eg11 from '../assets/engagement/Daphne+AnthonyEngagement-40.jpg'; import eg12 from '../assets/engagement/Daphne+AnthonyEngagement-45.jpg';
import eg13 from '../assets/engagement/Daphne+AnthonyEngagement-49.jpg'; import eg14 from '../assets/engagement/Daphne+AnthonyEngagement-62.jpg';
import eg15 from '../assets/engagement/Daphne+AnthonyEngagement-63.jpg'; import eg16 from '../assets/engagement/Daphne+AnthonyEngagement-65.jpg';
import eg17 from '../assets/engagement/Daphne+AnthonyEngagement-68.jpg'; import eg18 from '../assets/engagement/Daphne+AnthonyEngagement-72.jpg';
import eg19 from '../assets/engagement/Daphne+AnthonyEngagement-76.jpg'; import eg20 from '../assets/engagement/Daphne+AnthonyEngagement-80.jpg';
import eg21 from '../assets/engagement/Daphne+AnthonyEngagement-84.jpg'; import eg22 from '../assets/engagement/Daphne+AnthonyEngagement-87.jpg';
import eg23 from '../assets/engagement/Daphne+AnthonyEngagement-102.jpg';

/* Just Us */
import ju1 from '../assets/gallery/pic1.jpg'; import ju2 from '../assets/gallery/pic2.jpg';
import ju3 from '../assets/gallery/pic3.jpg'; import ju4 from '../assets/gallery/pic4.jpg';
import ju5 from '../assets/gallery/pic5.jpg'; import ju6 from '../assets/gallery/pic6.jpg';
import ju7 from '../assets/gallery/pic7.jpg'; import ju8 from '../assets/gallery/pic8.jpg';
import ju9 from '../assets/gallery/pic9.jpg'; import ju10 from '../assets/gallery/pic10.jpg';
import ju11 from '../assets/gallery/pic11.jpg'; import ju12 from '../assets/gallery/pic12.jpg';
import ju13 from '../assets/gallery/pic13.jpg'; import ju14 from '../assets/gallery/pic14.jpg';
import ju15 from '../assets/gallery/pic15.jpg'; import ju16 from '../assets/gallery/pic16.jpeg';
import ju17 from '../assets/gallery/pic17.jpeg'; import ju18 from '../assets/gallery/pic18.jpg';
import ju19 from '../assets/gallery/pic19.jpg'; import ju20 from '../assets/gallery/pic20.jpeg';
import ju21 from '../assets/gallery/pic21.jpeg'; import ju22 from '../assets/gallery/pic22.jpeg';
import ju23 from '../assets/gallery/pic23.jpeg'; 


/* Proposal */
import pr1 from '../assets/proposal/p1.jpg'; import pr2 from '../assets/proposal/p2.jpg';
import pr3 from '../assets/proposal/p3.jpeg'; import pr4 from '../assets/proposal/p4.jpeg';
import pr5 from '../assets/proposal/p5.jpeg';


function Gallery(){
    const modalWidth = window.outerWidth ? (window.outerWidth >= 770 ? (window.outerWidth * .7) : (window.outerWidth * .9)) : 500, 
        modalHeight = (window.outerWidth && (window.outerWidth < 770) ? (window.outerHeight * .7) : 500);

    const gallery_list = {
        "engagement": { "title":"Engagement", "images":[eg1,eg2,eg3,eg4,eg5, eg6,eg7,eg8,eg9,eg10,eg11,eg12,eg13,eg14,eg15,eg16,eg17,eg18,eg19,eg20,eg21,eg22,eg23]},
        "proposal": { "title":"Proposal", "images":[pr1, pr2, pr3, pr4, pr5]},
        "just_us": { "title":"Just Us", "images":[
            ju1, ju2, ju3, ju4, ju5, ju6, ju7, ju8, ju9, ju10, ju11, ju12, ju13, ju14, ju15,ju16, ju17, ju18, ju19, ju20, ju21, ju22, ju23
        ]},
    };

    const gallery_template = [
        {"row_w": 2, "items":[
            {"row_h":10, "items":[{"row_w":10 }]}
        ]},
        {"row_w": 3, "items":[
            {"row_h":5, "items": [{"row_w":10 }]}, 
            {"row_h":5, "items": [{"row_w":10 }]}
        ]},
        {"row_w": 2, "items":[
            {"row_h":6, "items": [{"row_w":10 }]},
            {"row_h":4, "items": [{"row_w":10 }]}
        ]},
        {"row_w": 3, "items":[
            {"row_h":4, "items": [{"row_w":10 }]},
            {"row_h":6, "items": [{"row_w":10 }]}
        ]}
    ];

    const [selectedGallery, setSelectedGallery] = useState("");
    const [selectedGalleryTitle, setSelectedGalleryTitle] = useState("");
    const [displayGallery, setDisplayGallery] = useState<Array<Array<GalleryCol>>>([]);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [galleryList, setGalleryList] = useState<Array<GalleryNavItem>>([]);
    const [isLoading, setIsLoading] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [selectedImg, setSelectedImg] = useState();
    const [highlightIndex, setHighlightIndex] = useState({ galleryKey: "", index: 0});

    const galleryRef = useRef(null);
    const galleryTopRef = useRef<HTMLDivElement>(null);

    const buildDisplayGallery = (gId) => {
        try {
            if(gId in gallery_list){
                let tmpGallery = gallery_list[gId];

                // Set Display Gallery Info
                setSelectedGalleryTitle(tmpGallery.title);

                // Build Display
                let tmp_display:GalleryCol[][] = [], 
                    tmp_gallery_col:GalleryCol[] = [],
                    galleryIdx = 0;

                for(let i=0; i < gallery_template.length; i++){
                    let tmp_col:GalleryCol = {"row_w": gallery_template[i].row_w, "items":[]};
                    for(let j=0; j < gallery_template[i].items.length; j++){
                        let tmp_col_item:GalleryColItem = {"row_h": gallery_template[i].items[j].row_h, "items":[]};

                        for(let k=0; k < gallery_template[i].items[j].items.length; k++){
                            // Break Loop If No More Images Exist
                            if(galleryIdx >= tmpGallery.images.length){
                                break;
                            }

                            let tmp_col_row:GalleryColRow = {
                                "row_w":gallery_template[i].items[j].items[k].row_w,                           
                                "image": tmpGallery.images[galleryIdx],
                                "idx": galleryIdx
                            };
                            tmp_col_item.items.push(tmp_col_row);
                            galleryIdx++;
                        }
                        tmp_col.items.push(tmp_col_item);

                        // Break Loop If No More Images Exist
                        if(galleryIdx >= tmpGallery.images.length){
                            break;
                        }
                    }
                    tmp_gallery_col.push(tmp_col);
                    
                    // Break or Reset Loop If More Images Await
                    if(galleryIdx >= tmpGallery.images.length){
                        tmp_display.push(JSON.parse(JSON.stringify(tmp_gallery_col)));
                        break;
                    }
                    else if((i+1) >= gallery_template.length){
                        i=-1; tmp_display.push(JSON.parse(JSON.stringify(tmp_gallery_col)));
                        tmp_gallery_col = [];
                    }
                }

                // Set Display Gallery
                setDisplayGallery(tmp_display);
            }
        }
        catch(ex){
            console.log(`[Error] Building Display Gallery: ${ex}`);
        }
    }

    const buildGalleryList = () => {
        let ret:GalleryNavItem[] = [];
        try {
            Object.keys(gallery_list).forEach((key) =>{
                let tmp_item = gallery_list[key];

                ret.push({"title": tmp_item.title,"key":key,"defaultImg": tmp_item.images[0] });
            });

            setGalleryList(ret);
        }
        catch(ex){
            console.log(`[Error] Building Gallery List: ${ex}`);
        }
    }
    
    const pageNav = (val) =>{
        try {
            if(val < 0 && currentSlide > 0){
                setCurrentSlide((d)=> d-1);
            }
            else if(currentSlide < (displayGallery?.length - 1)) {
                setCurrentSlide((d)=> d+1);
            }
        }
        catch(ex){
            console.log(`[Error] Changing Page: ${ex}`);
        }
    }

    const highlightNav = (val) =>{
        try {
            let tmp_gallery = gallery_list[highlightIndex.galleryKey].images;

            if(val < 0){
                let next_num = (highlightIndex?.index > 0 ? highlightIndex?.index - 1: tmp_gallery.length - 1);
                highlightImage(next_num,selectedGallery);
            }
            else {
                let next_num = (highlightIndex?.index < (tmp_gallery?.length - 1) ? highlightIndex?.index + 1 : 0);
                highlightImage(next_num, selectedGallery)
            }
        }
        catch(ex){
            console.log(`[Error] Changing Highlight: ${ex}`);
        }
    }

    const highlightImage = (idx, key) => {
        try {
            setHighlightIndex({ galleryKey: key, index: idx });
        }
        catch(ex){
            console.log(`[Error] Highlighting Image: ${ex}`);
        }
    }

    const buildGalleryDisplay = () => {
        let ret;
        try {
            ret = displayGallery.map((gal_map,i) =>(
                <div className='gallery-grid' key={i}>
                    {gal_map.map((col, j) =>
                        <div className={`grid-col w-sz-${col.row_w}`} key={j}>
                            {col.items.map((c_item,k) =>
                                <div className={`grid-col-item h-sz-${c_item.row_h}`} key={k}>
                                    {c_item.items.map((row, l) =>
                                        <div className={`grid-row w-sz-${row.row_w}`} key={l} onClick={()=> { highlightImage(row.idx,selectedGallery); setShowModal(true); }}>
                                            {row.image ? <img src={row.image} /> : <div className='no-img' />}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            ));
        }   
        catch(ex){
            console.log(`[Error] Building Gallery Display: ${ex}`);
            ret = <div className='no-gallery' />
        }

        return ret;
    }

    const pageScroll = (myRef) => {
        try {
            if(myRef && myRef.current){
                let offsetWidth = myRef.current.offsetWidth;
                if(offsetWidth <= 770){
                    myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }                
            }
        }
        catch (ex) {
            console.log(`Error moving to section ${ex}`);
        }
    }

    useEffect(()=>{ 
        setIsLoading(true);
        buildDisplayGallery(selectedGallery); 
        setTimeout(function(){ setIsLoading(false); }, 1000);
    },[selectedGallery]);

    useEffect(()=>{ 
        buildGalleryList(); 
        setSelectedGallery("engagement"); 
    },[]);

    useEffect(()=>{
        if(highlightIndex.galleryKey && highlightIndex.index >= 0){
            let tmp_img = gallery_list[highlightIndex.galleryKey].images[highlightIndex.index];
            setSelectedImg(tmp_img); 
        }         
    },[highlightIndex])

    return (
        <div className="page-container gallery">
            <section className='inner-page-title'>
                <div className='page-img-cover'>
                    <img src={header_img} alt="Couple image" className='page-img' />
                    <img src={header_img} alt="Couple image" className='page-img cover' />
                </div>
                
                <h1>Gallery</h1>
            </section>

            <section className='gallery-list-container'>
                {galleryList.map((item,i) =>
                    <div className={`gallery-item ${item?.key === selectedGallery ? 'sel' : ''}`} key={i} onClick={()=> { 
                        setSelectedGallery(item.key); setCurrentSlide(0); pageScroll(galleryTopRef); 
                        ReactGA.event({ category: "Gallery", action: `view_gallery_${item.key}` });
                        }}>
                        <div className='item-img-base'>
                            <div className='item-img-container'>
                                <img src={item.defaultImg} alt={`${item?.title} cover`}/>
                            </div>
                        </div>
                        <div className='gallery-title'>{item.title}</div>
                    </div>
                )}
            </section>

            <section className='gallery-section' ref={galleryTopRef}>
                {isLoading && <div className='loader-container'><div className='loader' /></div>}
                <div className='gallery-ctrl'>
                    <div className={`ctrl-item ${currentSlide == 0 ? 'disabled':''}`} onClick={()=> pageNav(-1)}>
                        <span className="material-symbols-outlined">arrow_back</span>
                        <span>Previous</span>
                    </div>
                    <div className='gallery-title'>{selectedGalleryTitle}</div>
                    <div className={`ctrl-item ${(currentSlide + 1) >= displayGallery?.length ? 'disabled':''}`} onClick={()=> pageNav(1)}>
                        <span>Next</span>
                        <span className="material-symbols-outlined">arrow_forward</span>
                    </div>
                </div>
                <Carousel className="gallery-carousel" showThumbs={false} selectedItem={currentSlide} 
                    ref={galleryRef} showStatus={false} showIndicators={false} showArrows={false}>
                    {buildGalleryDisplay()}
                </Carousel>

                <div className='mobile-gallery'>{buildGalleryDisplay()}</div>
            </section>

            {/* Image Modal */}
            <Rodal visible={showModal} onClose={()=> setShowModal(false)} animation={"fade"} width={modalWidth} height={modalHeight}>
                <div className='modal-container'>
                    <div className='gallery-ctrl'>
                        <div className={`ctrl-item`} onClick={()=> highlightNav(-1)}>
                            <span className="material-symbols-outlined">arrow_back</span>
                            <span>Previous</span>
                        </div>
                        <div className='gallery-title'>{selectedGalleryTitle}</div>
                        <div className={`ctrl-item`} onClick={()=> highlightNav(1)}>
                            <span>Next</span>
                            <span className="material-symbols-outlined">arrow_forward</span>
                        </div>
                    </div>
                    <div className='img-container'>
                        <img src={selectedImg} alt={`Selected Image From ${selectedGalleryTitle}`}/>
                    </div>
                </div>
                <div className='back-modal-img'><img src={cherry_b} /></div>
            </Rodal>
        </div>
    );
}

export default Gallery;