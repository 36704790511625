import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import { weddingDate } from './utils';
import Monogram from './monogram';

function Footer(){
    return (
        <div className="footer-container">
            <Link className='footer-link' to="/">
                <Monogram />
            </Link>

            <div className='footer-line'><span>{format(weddingDate, 'MM.dd.yyyy')}</span></div>
            <div className='custom-back' />
        </div>
    );
}

export default Footer;