import "./styles/app.less";

import { Routes, Route, useLocation  } from "react-router-dom";
import ReactGA from "react-ga4";
import { useEffect } from 'react';

import Layout from './components/layout';

import Home from "pages/home";
import OurStory from "pages/ourstory";
import NoMatch from "pages/nomatch";
import Gallery from "pages/gallery";
import RSVP from "pages/rsvp";
import Registry from "pages/registry";
import FAQs from "pages/faqs";

function App(){
    const { pathname } = useLocation();
    
    useEffect(()=> { window.scrollTo(0, 0);  },[pathname]);

    return(
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="ourstory" element={<OurStory />} />
                <Route path="gallery" element={<Gallery />} />
                <Route path="rsvp" element={<RSVP />} />
                <Route path="registry" element={<Registry />}/>
                <Route path="faqs" element={<FAQs />}/>
                
                <Route path="*" element={<NoMatch />} />
            </Route>
        </Routes>
    )
}

export default App;