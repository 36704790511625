import React from "react";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import ReactGA from "react-ga4";
import { measurmentId } from "components/utils";

import App from "./App";
import "./styles/app.less";

ReactGA.initialize(measurmentId);

const appRoot = createRoot(document.getElementById('app') as HTMLElement);
appRoot.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>
);