import { useState } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from './header';
import Footer from './footer';
import cherry_b from 'assets/cherry_b_bw.png';

function Layout(){
    const [subNavOpen, setSubNavOpen] = useState(false);

    const displayNav = (status) => {
        document.body.classList.toggle('noscroll', status);
        setSubNavOpen(status);
    }

    return (
        <div className='app-body'>
            <ToastContainer />
            <Header displayNav={displayNav} subNavOpen={subNavOpen} />
            <div className={`app-container ${subNavOpen ? "lock" : ""}`}>
                <div className='page-body'>
                    <Outlet />
                    <Footer />
                    <NavLink to="/faqs" className='faq-btn'>
                        <span className="material-symbols-outlined icon">help</span>
                        <span className='link-text'>FAQs</span>
                    </NavLink>
                </div>
                <div className='page-navigation'>
                    <NavHashLink className="nav-link" to="/#weddingEvents" onClick={()=> displayNav(false)}>Wedding Events</NavHashLink>
                    <NavLink className="nav-link" to="/ourstory" onClick={()=> displayNav(false)}>Our Story</NavLink>
                    <NavLink className="nav-link" to="/gallery" onClick={()=> displayNav(false)}>Gallery</NavLink>
                    <NavLink className="nav-link" to="/registry" onClick={()=> displayNav(false)}>Registry</NavLink>
                    <NavLink className="nav-link" to="/rsvp" onClick={()=> displayNav(false)}>RSVP</NavLink>
                    <NavLink className="nav-link" to="/faqs" onClick={()=> displayNav(false)}>FAQs</NavLink>
                </div>

                <div className='back-img'><img src={cherry_b} /></div>
            </div>
        </div>
    );
}

export default Layout;