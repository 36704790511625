import { useState, useEffect } from 'react';
import header_img from '../assets/engagement/Daphne+AnthonyEngagement-84.jpg';
import ReactGA from "react-ga4";

function FAQs(){
    const [sel, setSel] = useState(-1);

    const data = [
        { question: "Will the wedding be indoors or outdoors?", answer:[
            {bold: false, text: "The ceremony will be outside (be aware the venue does not have covering or shading), but the reception will be indoors."}
        ] },
        { question: "What should I expect after the ceremony?", answer:[{bold: false, text: "After the ceremony, the couple, bridal party, and immediate family will take pictures for around an hour. Guests can walk over to the pole barn for cocktail hour. Dinner will be served around 6 pm, with dancing to follow." }]},
        { question: "Is there easy parking at the venue(s)?", answer:[
            {bold: true, text:"Yes,"},
            {bold: false, text: "King Cole Farm has plenty of complimentary parking.  Please stay safe and default to a ride service or carpooling if you plan to drink." }
        ]},
        { question: "How do I RSVP?", answer:[
            {bold: false, text: "We hope you’ll be able to join us for the big day. With your invitation, there will be an RSVP card." },
            {bold: true, text:"Scan the QR code and enter the password."},
        ]},
        { question: "Can I bring my partner?", special: true, answer:[
            {bold: true, text:"NO."},
            {bold: false, text: "We will only be able to accommodate those listed on your invitation." }
        ]},
        { question: "Can I bring my kids?", special: true, answer:[
            {bold: true, text:"NO."},
            {bold: false, text: "Since we have huge extended families, we’ve decided to limit our guest list to fourteen-year-olds and over, and we hope you understand." }]
        },        
        { question: "When is the RSVP deadline?", answer:[
            {bold: false, text:"Please by,"},
            {bold: true, text: "Tuesday, August 20th" },
            {bold: false, text:"so we have time to submit all the details on our end."},
        ]},
        { question: "I have dietary restrictions, what’s the best way to communicate that with you?", answer:[{bold: false, text: "We want to make sure you enjoy your time and meal on our wedding day, please let us know when you RSVP about your dietary needs." }]},
        { question: "Are the ceremony and reception location wheelchair accessible?", answer:[
            {bold: true, text:"Yes,"},
            {bold: false, text: "The pole barn is fully compliant with all ADA requirements." }
        ]},
        { question: "Can I take and post pictures of the wedding on social media?", answer:[
            {bold: false, text: "We love photos! We do ask that you refrain from taking photos during the ceremony, we’d like that time to be" },
            {bold: true, text:"unplugged."},
            {bold: false, text:"For the rest of the wedding, we encourage guests to post pictures using our wedding hashtag"},
            {bold: true, text:"#pearsonmyheart24"},
            {bold: false, text:"so we can see them, too!"},
        ]}
    ];

    const toggleSel = (idx) => {
        try {
            setSel(((sel === idx) ? -1 : idx));

            let question = data[idx].question.split(" ").join("_");
            ReactGA.event({ category: "FAQ", action: `toggleQuestion_${question}` });
        }
        catch(ex){
            console.log(`Error Toggling Selection: ${ex}`);
        }
    }

    return (
        <div className="page-container faqs">
            <section className='inner-page-title'>
                <div className='page-img-cover'>
                    <img src={header_img} alt="Couple image" className='page-img' />
                    <img src={header_img} alt="Couple image" className='page-img cover' />
                </div>
                
                <h1>FAQs</h1>
            </section>

            <div className='faq-section'>
                <div className='faq-container'>
                    {data.map((item,i) => 
                        <div className='qa-item' key={i}>
                            <div className='question-container'>
                                <div className={`question ${item?.special == true ? 'special' : ''}`} onClick={()=>{ toggleSel(i) }}>{item.question}</div>
                                <div className='section-toggle' onClick={()=>{ toggleSel(i) }}>
                                    <span className="material-symbols-outlined icon">{sel === i ? "remove":"add"}</span>
                                </div>
                            </div>
                            <div className={`answer-container ${sel === i ? 'opened' : ''}`}>
                                <div className='answer'>
                                    {item.answer.map((answer,i)=>
                                        (answer.bold ? <b key={i}>{`${answer.text} `}</b> : <span key={i}>{`${answer.text} `}</span>)
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default FAQs;