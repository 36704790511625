import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import { weddingDate } from './utils';
import Monogram from './monogram';

function Header({ displayNav, subNavOpen }){
    return (
        <nav className="navbar navbar-expand-lg">
            <Link className='navbar-brand' to="/" onClick={()=> displayNav(false)}>
                <Monogram />
            </Link>

            <div className='nav-item date'><span>{format(weddingDate, 'MM.dd.yyyy')}</span></div>

            <div className='nav-item menu' onClick={()=> { displayNav(!subNavOpen)}}>
                {/*<span className="material-symbols-outlined">{subNavOpen ? "close": "menu_open"}</span>*/}
                <div className={`wrapper-menu ${subNavOpen ? 'open' : ''}`}>
                    <div className="line-menu half start" />
                    <div className="line-menu" />
                    <div className="line-menu half end" />
                </div>
            </div>
        </nav>
    );
}

export default Header;