import { useRef, useState, useEffect } from 'react';

import { PageFlip } from "page-flip";
import HTMLFlipBook from "react-pageflip";

// Flip Book Images
import cover from '../assets/storybook/cartoon2.jpg';
import page1 from '../assets/storybook/1stdance.jpg';
import page2 from '../assets/storybook/our_story.jpg';
import page3 from '../assets/storybook/pic6.jpg';
import page4 from '../assets/storybook/pic9.jpg';
import page5 from '../assets/storybook/engage1.jpg';
import page6 from '../assets/storybook/engage2.jpg';

const DEFAULT_BOOK_WIDTH = 550,
    DEFAULT_BOOK_HEIGHT = 620;

function StoryBook(){
    const pageData = [
        { "header":"How We Met", "image":page1, "signature":"D", "text":["We met as walking partners at a mutual friend's wedding on a freezing cold November 2019. Little did we know it would just be a practice run for the future. We spent the majority of the night together talking, dancing, and…"] },
        { "header":null, "image":null, "signature":"D", "text":["By the end of the wedding we almost went our separate ways, but thanks to some \"gentle\" nudging and prompts from friends I took Anthony's phone from his hand and put my phone number into it with the bold statement of", "\"Make sure you do something smart with this!\" Thankfully he did, and texts/calls haven't stopped since."] },
        { "header":"Dating Chronicles", "image":page2, "signature":"A", "text":["With me being a member of the United States Navy living in Virginia they certainly knew how to keep me busy.  Meanwhile, Daphne was molding the minds of her students or keeping up her socialite status in Delaware."] },
        { "header": null, "image":page3, "signature":"A", "text":["Thank goodness for modern technology! Our relationship has been communicated over Facetime, texts, emails, Route 1, and I95. I have traveled many hours and miles on the highways just to spend weekends with Daphne."] },
        { "header":null, "image":null, "signature":"D", "text":["I was always so grateful and excited for every weekend I spent with Anthony. Virtual dating was nice, but hugs and kisses IRL seem even more special when you truly have to wait for them. It made us cherish the time we spent together because 48 hours or less flies by faster than you think.", "Even with this weekend being so short, we never failed to fill them with fun and laughter." ] },
        { "header": null, "image":page4, "signature":"D", "text":["Whether it was attending a Ravens game, game night with friends, COVID home projects, or convincing me that I could survive both a scary movie and spicy food, boredom was not an option."] },
        
        { "header":"Engagement (Her Side)", "image":page5, "signature":"D", "text":["What I thought was going to be a birthday brunch with friends, turned into the biggest surprise ever. We all arrived at BondHouse, and everyone was asking where’s AP (Anthony)? I had become accustomed to the question with his busy life in the Navy."] },
        { "header":null, "image":null, "signature":"D", "text":[
            "Just after the first drinks arrived, so did a mystery box for me… cue the waterworks!", 
            "Everyone quickly began making fun of me, because I was crying at just a box. But it wasn't just a box for me, it was the gesture of making sure I had something from Anthony to open on my birthday.",
            "I opened the box to find an envelope \"Opps I messed up the label\"  inside was a new tag that said: \"From Your Fiancé\"! \"Fiancé… I don't have one of those!?\" 2 minutes later (but it felt like an eternity) out walks  Anthony… recue the tears… quietest, easiest YES I have ever whispered!"
        ]},

        { "header":"Engagement (Now My Side)", "image":null, "signature":"A", "text":[
            "I had decided on our anniversary that I would propose to her on her birthday. To ramp up the surprise factor, I also told her that I had to work on her birthday and wouldn’t be able to celebrate with her.",
            "This slightly backfired on me because Daphne had no idea what she wanted to do or where she wanted to go, which made planning anything elaborate borderline impossible. Once she finally settled on a brunch with friends in Baltimore, I got to work. With the help of her friends, I was able to get to the restaurant without being seen.",
            " I had a gift box that I had the waitress give to her at her table that was labeled “To: My Girlfriend, From: Your Boyfriend”. Inside the box, there was an envelope with the phrase “Oops, I got the label wrong” written on it. Inside the envelope was another label that said: \"To: My Fiancée, From: Your Fiancé\"."
        ]},
        { "header":null, "image":page6, "signature":"A", "text":[
            "Daphne was already in tears upon receiving the box knowing it was a gift from me despite me not being able to be there because I was “working”, so once I revealed myself the shock value went through the roof! I took her hand and knelt down in front of her and asked", "\"Will you marry me?\""
        ]}
    ];

    const [page, setPage] = useState(0);
    const [totalPage] = useState((pageData.length + 1));
    const [isLoading, setIsLoading] = useState(true);

    const [bookInitialized, setBookInitialized] = useState(false);
    const [isMobile, setIsMobile] = useState(true);
    const [bookWidth, setBookWidth] = useState(DEFAULT_BOOK_WIDTH);
    const [bookHeight, setBookHeight] = useState(DEFAULT_BOOK_HEIGHT);

    const flipBookRef = useRef<PageFlip>(null);

    const onFlip = (e) =>{
        try {
            if(e?.data){
                setPage(e.data);
            }
        }
        catch(ex){
            console.log(`[Error] On Flip ${ex}`);
        }
    }

    const flipBook = (val) => {
        try {
            if(val < 0 && page > 0){
                flipBookRef?.current?.pageFlip().flipPrev();
            }
            else if(page < totalPage){
                flipBookRef?.current?.pageFlip().flipNext();
            }
        }
        catch(ex){
            console.log(`[Error] Flipping Book: ${ex}`);
        }
    }

    const handleResize = () => { 
        if(window.innerWidth < (2 * DEFAULT_BOOK_WIDTH)){
            setIsMobile(true);
            setBookWidth(window.innerWidth - 20);
            setBookHeight(700);
        }
        else if(isMobile == true){
            setIsMobile(false); 
            setBookWidth(DEFAULT_BOOK_WIDTH);
            setBookHeight(DEFAULT_BOOK_HEIGHT);
        }

        setBookInitialized(true);
    }

    useEffect(()=> { 
        // Call to set initial book size
        handleResize();
    },[]);

    useEffect(()=> {
        if(bookInitialized && isLoading){
            setTimeout(function(){ setIsLoading(false); }, 1500);
        }
    },[bookInitialized])


    return (
        <div className="storybook">
            { isLoading && <div className='loader-container'><div className='loader' /></div>}
            {!bookInitialized ? <div className='empty-book-container' /> :
                <> 
                    <div className='book-ctrl-container'>
                        <span className={`material-symbols-outlined ${page == 0 ? 'disabled':''}`} onClick={()=> flipBook(-1)}>skip_previous</span>
                        <span className={`material-symbols-outlined ${page >= totalPage ? 'disabled':''}`} onClick={()=> flipBook(1)}>skip_next</span>
                    </div>

                    <HTMLFlipBook
                        width={bookWidth} height={bookHeight} size="fixed"
                        minWidth={315} maxWidth={1000}
                        minHeight={400} maxHeight={1350}
                        maxShadowOpacity={0.5} onFlip={onFlip}
                        showCover={true} mobileScrollSupport={true}
                        className="pearson-book" ref={flipBookRef} 

                        style={{}} startPage={0} 
                        drawShadow={true} flippingTime={1000} usePortrait={isMobile} 
                        startZIndex={0} autoSize={true} clickEventForward={true} 
                        useMouseEvents={true} swipeDistance={30} showPageCorners={true} 
                        disableFlipByClick={false}>
                        {/* Cover */}
                        <div className="page-cover">
                            <div className='bend-line'/>
                            <div className="cover-content">
                                <img src={cover} className='cover-img' />
                                <div className='cover-text b-title'>
                                    <div className='book-title'>
                                        <span>Forever</span> 
                                        <span className='lrg'>&</span>
                                        <span>Then Some</span>
                                    </div>
                                    <div className='book-sub-title'>Our Love Story</div>
                                </div>

                                <div className='cover-text w-by'>
                                    <span className="by-title">Written By</span>
                                    <span>Your Favorite Couples, Favorite Couple</span>
                                </div>                   
                            </div>
                        </div>
                        {/* Pages */}
                        {pageData.map((page,i) => 
                            <div className="page" key={i}>
                                <div className="page-content">
                                    <div className="page-header">{page?.header}</div>
                                    {page?.image != null ?
                                        <>
                                            <div className="page-image">
                                                <img src={page?.image} />
                                            </div>
                                            <div className='spacer' />
                                        </>:
                                        <><div className='empty-page-image'/></>
                                    }
                                    
                                    <div className={`page-text ${page?.image === null ? 'no-img' : ''}`}>
                                        {page.text.map((sentence, i) => <p key={i}>{sentence}</p>)}
                                    </div>
        
                                    <div className={`page-footer ${i%2 == 0 ? 'left' : 'right'}`}>
                                        <span>{i+1}</span>
                                        <div className='signature'>
                                            {page?.signature === "D" ? <div className='sig-note daphne'>Daphne</div> : <></>}
                                            {page?.signature === "A" ? <div className='sig-note ant'>Anthony</div> : <></>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="page-cover end">
                            <div className='bend-line'/>
                            <div className="cover-content">
                                <div className='cover-text e-title'>
                                    <div className='end-title'>... And The Rest Is History</div>
                                </div>
                            </div>
                        </div>                    
                    </HTMLFlipBook>
                </>
            }
        </div>
    );
}

export default StoryBook;