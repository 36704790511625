import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import ReactGA from "react-ga4";

import RsvpForm from 'components/rsvpForm';

import { rsvpDate } from 'components/utils';
import header_img from '../assets/reception.jpg';

const FORM_KEY = 'pearson2024';

function RSVP(){
    const [rsvpKey, setRsvpKey] = useState("");
    const [unlockForm, setUnlockForm] = useState(false);

    const resetRSVPKey = () => {
        setRsvpKey(""); setUnlockForm(false);
    }

    const checkKey = () => {
        if(rsvpKey.toLowerCase() != FORM_KEY){
        toast.warning("Please double check your invitation for the RSVP key.", { position: "top-right",
                    autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true,
                    draggable: true, progress: undefined, theme: "light", });
        }
    }

    useEffect(()=> { 
        if(rsvpKey.toLowerCase() === FORM_KEY){
            setUnlockForm(true);
            ReactGA.event({ category: "RSVP Form", action: "unlock_rsvp" });
        }
    }, [rsvpKey])

    return (
        <div className="page-container rsvp">
            <section className='inner-page-title'>
                <div className='page-img-cover'>
                    <img src={header_img} alt="RSVP image" className='page-img img-cover' />
                </div>
                
                <h1>RSVP</h1>
            </section>
            <section className='rsvp-address-section'>
                <p>
                    We greatly appreciate all of the love and support as we have received.  
                    As we finalize planning for our special day we ask that you use the password in your invitation to
                </p>
                <p><span>RSVP here by {format(rsvpDate, 'EEEE, MMMM dd, yyyy')}</span>.</p>

                <p>Although we wish we could invite everyone <b>please respect the reserved seating count on your invitation</b> when adding your additional guests.</p>
            </section>

            <section className='rsvp-form-section'>
                {/*unlockForm ? <RsvpForm resetKey={resetRSVPKey}/>: 
                    <div className='form-key-container'>
                        <div className='key-icon' onClick={checkKey}>
                            <span className="material-symbols-outlined">lock</span>
                        </div>
                        <input type="text" name="rsvpKey" value={rsvpKey} onChange={(e) => setRsvpKey(e.target.value)}/>
                    </div>
                */}
                <div className='rsvp-close-card'>
                    <p>Unfortunately the open RSVP for our wedding <b>has closed</b></p>
                    <p>for those who have already responed we look forward to seeing you.</p>
                    <p className='new-line'>If you need to make any changes to your attendance status</p>
                    <p><b>please let us know ASAP</b> as we are now finalizing the list.</p>
                </div>                
            </section>
        </div>
    );
}

export default RSVP;